import Image from 'next/image'

import { useOneTrust } from '@cms/hooks/useOneTrust'
import type { FormattedVideoProps } from '@cms/utils/types'
import { getDefaultImageLoader } from '@cms/utils/utils'
import { formattedVideo } from '@cms/utils/video'
import type { VideoTileListProps } from '@knauf-group/ct-designs/components/core/VideoTileList'
import { VideoTileList } from '@knauf-group/ct-designs/components/core/VideoTileList'
import type { VideoProps } from '@knauf-group/ct-designs/utils/types'
import type {
  TypeContentDamVideoSkeleton,
  TypeContentVideoGallerySkeleton,
} from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'

import { ChangeCookieSettingsCTA } from '../ChangeCookieSettingsCTA'
import VideoCookieWarning from './VideoCookieWarning'

const formatVideoList = (
  videoList: ContentEntry<TypeContentVideoGallerySkeleton>['fields'],
  isTargetingCookieSet: boolean,
): VideoProps[] => {
  const cookieBanner = <ChangeCookieSettingsCTA showCookieAlert={false} />
  return (videoList?.videos as ContentEntry<TypeContentDamVideoSkeleton>[])
    ?.filter((video) => video.fields) // Filter out draft content from contentful
    .map((videoTile, index) => {
      const formatedVideoProps: FormattedVideoProps = {
        videoContent: videoTile,
        index,
        isTargetingCookieSet,
        cookieSettingsTile: cookieBanner,
      }
      return formattedVideo(formatedVideoProps)
    })
}

const VideoTileListWrapper: React.FC<ContentEntry<TypeContentVideoGallerySkeleton>> = ({
  fields: videoTileListContent,
}) => {
  const oneTrust = useOneTrust()

  const videos = formatVideoList(videoTileListContent, oneTrust.isTargetingCookieSet)
  const isVideoCookieAccepted = videos.some((video) => video?.isVideoCookieAccepted === false)

  const videoGalleryProps: VideoTileListProps = {
    headline: videoTileListContent?.headline,
    description: videoTileListContent?.text,
    videos,
    cookieSettingsAlertComponent: <VideoCookieWarning />,
    isVideoCookieAccepted,
    nextImageComponent: Image,
    imageLoader: getDefaultImageLoader(),
  }

  return <VideoTileList {...videoGalleryProps} />
}

export default VideoTileListWrapper
